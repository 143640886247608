import React, { Ref } from "react";
import { Formik, Form, useFormikContext, FormikProps } from "formik";
import {
	RegisterEmailFormInitValues,
	RegisterEmailFormValidation,
} from "./RegisterEmailPhoneValidationForm";
import { t } from "i18next";
import Field from "../../components/molecules/Field/Field";
import { Button } from "@zolteam/react-ras-library";

interface IRegisterEmailFormProps {
	handleSubmitForm: (
		values: { email: string },
		resetForm: (
			nextState?: Partial<typeof useFormikContext> | undefined
		) => void
	) => void;

	email?: string;
	formRef?: Ref<FormikProps<any>> | undefined;
	validateStep?: (values: {}) => void;
	submitButton?: boolean;
	submitButtonText?: string;
	submitButtonDisabled?: (values: { email: string }) => boolean;
}

const RegisterMailInput = (props) => {
	const { setFieldTouched, initialValues, values } = useFormikContext<{
		email: string;
		emailConfirmation: string;
	}>();

	React.useEffect(() => {
		if (props.email) setFieldTouched("email", true, true);
	}, [setFieldTouched, props.email]);

	return (
		<div className="flex flex-col gap-4">
			<Field
				name={"email"}
				label={t("registerEmail.email")}
				type="text"
			/>
			{values.email !== initialValues.email && (
				<Field
					name="emailConfirmation"
					label={t("initRegister.emailConfirm")}
					type="text"
				/>
			)}
		</div>
	);
};

export const RegisterEmailForm: React.FC<IRegisterEmailFormProps> = (
	props: IRegisterEmailFormProps
) => {
	const RegisterEmailValueSchema = props.email
		? {
				email: props.email,
				emailConfirmation: null,
		  }
		: {
				...RegisterEmailFormInitValues,
		  };

	return (
		<Formik
			initialValues={RegisterEmailValueSchema}
			validationSchema={RegisterEmailFormValidation(t)}
			onSubmit={(values, { resetForm }) => {
				return props.handleSubmitForm(values, resetForm);
			}}
			validateOnChange={true}
			validate={(values: typeof RegisterEmailValueSchema) => {
				props.validateStep && props.validateStep(values);
			}}
			innerRef={props.formRef}
		>
			{({ isSubmitting, isValid, values }) => (
				<Form>
					<RegisterMailInput email={props.email} />
					{props.submitButton && (
						<Button
							type="submit"
							color={isValid ? "primary" : "grey"}
							className="w-full mt-4"
							isLoading={isSubmitting}
							disabled={
								!isValid || props.submitButtonDisabled?.(values)
							}
						>
							{props.submitButtonText || t("global.confirm")}
						</Button>
					)}
				</Form>
			)}
		</Formik>
	);
};
