import React, { Ref, useContext, useState } from "react";
import { t } from "i18next";
import { Title } from "@zolteam/react-ras-library";
import { ResetPasswordForm } from "../../forms/ResetPasswordForm/ResetPasswordForm";
import RegisterService from "../../services/RegisterService";
import { FormikProps } from "formik";
import AuthService from "../../services/AuthService";
import {
	StepsContextType,
	StepsContext,
} from "../../contexts/StepsContext/StepsContext";

interface IRegisterPasswordProps {
	formRef: Ref<FormikProps<any>> | undefined;
	setStepsContext?: (context: any) => void;
	stepsContext: any;
	validateStep: (values: {}) => void;
}
const RegisterPassword: (props: IRegisterPasswordProps) => void = (
	props: IRegisterPasswordProps
) => {
	const { uniqueId, validationCode } =
		useContext<StepsContextType>(StepsContext);

	const [queryState, setQueryState] = useState<any>({
		isLoading: false,
		success: false,
		error: "",
	});
	const handleSubmitForm = ({
		password,
	}: {
		password: string;
		uniqueId: string;
		activationCode: string;
	}) => {
		setQueryState({
			isLoading: true,
			success: false,
			error: "",
		});
		let prom = new Promise((resolve, reject) => {
			return RegisterService.postRegisterPassword(
				password,
				uniqueId,
				validationCode
			)
				.then(
					(res) =>
						AuthService.login(uniqueId, password, {
							isOnboarding: true,
							isInitied: true,
						}),
					(err) => {
						const msg =
							err?.response?.data?.message ??
							"API_ERRORS.default";
						setQueryState({
							isLoading: false,
							success: false,
							error: msg,
						});
						reject(new Error(t(msg).toString()));
						return false;
					}
				)
				.then(
					(res) => {
						setQueryState({
							isLoading: false,
							success: true,
							error: "",
						});
						resolve(res);
					},
					(err) => {
						setQueryState({
							isLoading: false,
							success: false,
							error: err.response.data.message,
						});
						reject(err);
					}
				);
		});
		return prom;
	};

	return (
		<div>
			<Title tag="h2" lineHeight="m" size="heading01" className={"pt-m"}>
				{t("passwordRegister.password")}
			</Title>
			<ResetPasswordForm
				load={queryState.isLoading}
				handleSubmitForm={handleSubmitForm}
				formRef={props.formRef}
				validateStep={props.validateStep}
			/>
		</div>
	);
};

export default RegisterPassword;
