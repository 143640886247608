import axios from "axios";
import TokenService from "./TokenService";
import { API_URL } from "../constants/constants";
import { toast } from "react-toastify";
import { t } from "i18next";
import { QueryFunction, QueryKey } from "@tanstack/react-query";

export interface IQuery {
	key: QueryKey; // key to use for the query
	fn: QueryFunction<any, QueryKey>; // function to fetch items
	opts?: any; // options to pass to the query
}

const Axios = axios.create({
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

Axios.interceptors.request.use(
	async (config) => {
		const shouldRefreshToken = TokenService.shouldRefreshToken();

		if (shouldRefreshToken) {
			await TokenService.refreshToken();
		}

		const token = TokenService.getLocalAccessToken();
		if (token && config?.headers) {
			config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
		}

		return config;
	},
	(error) => {
		console.error(error);
		return Promise.reject(error);
	}
);

Axios.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config ?? {};
		const resp = err.response;
		// return early because we won't handle common errors in this interceptor
		if (resp?.status !== 401 || originalConfig._retry) {
			return Promise.reject(err);
		}

		if (resp?.status === 429) {
			// Permet d'afficher une bannière d'erreur pour les erreurs 429
			toast.error(t("API_ERRORS.ERR_429_TOO_MANY_REQUESTS").toString(), {
				autoClose: false,
				toastId: "Error-429-max-requests",
			});
		} else if (
			originalConfig?.url === "/api/token/refresh" &&
			err.response
		) {
			// Access Token was expired and refresh token also expired
			console.warn("Access Token was expired, logging out...");
			if (resp?.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true;
				TokenService.removeUser();
				// Le refresh de la page est dans IdleModal.tsx
			}
		} else if (originalConfig?.url !== "/api/login_check" && err.response) {
			// Access Token was expired
			if (resp?.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true;

				// try to refresh token
				try {
					await TokenService.refreshToken();
					return Axios(originalConfig);
				} catch (_error) {
					return Promise.reject(_error);
				}
			}
		}

		return Promise.reject(err);
	}
);

export const getAPIError = (
	error: any,
	validationKey: string = "",
	noTranslation = false
) => {
	let msg = error.response?.data?.message || error.data?.message || "default";
	if (msg === "Validation errors" && error.response?.data?.errors?.length) {
		const findKeyItem = error.response.data.errors.find(
			(e) => e[validationKey]
		);
		msg = findKeyItem?.[validationKey]
			? findKeyItem[validationKey]
			: "default";
	}

	if (noTranslation) {
		return msg;
	}

	let trad = t(`API_ERRORS.${msg}`);
	if (trad === `API_ERRORS.${msg}`) {
		// if no translation found
		trad = t("API_ERRORS.default");
	}
	return trad;
};

export default Axios;
