/* eslint-disable max-len */
import React, { RefObject, useState } from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";

import { useQuery } from "@tanstack/react-query";
// Templates
import InitLayout, {
	IStep,
} from "../../components/templates/InitLayout/InitLayout";

// Forms
import { RegisterForm } from "../../forms/RegisterForm/RegisterForm";

import Locker from "../../assets/illustrations/password.svg";
import Mail from "../../assets/illustrations/email.svg";
import Phone from "../../assets/illustrations/contact.svg";
import Calendar from "../../assets/illustrations/calendar.svg";

import RegisterService from "../../services/RegisterService";
import RegisterPassword from "./RegisterPassword";
import { RegisterFormValidation } from "../../forms/RegisterForm/validationForm";
import { toast } from "react-toastify";
import { ResetPasswordFormValidation } from "../../forms/ResetPasswordForm/validationForm";
import RegisterAvailabilities from "./RegisterAvailabilities";
import RegisterEmail from "./RegisterEmail";
import {
	RegisterEmailFormValidation,
	RegisterPhoneFormValidation,
} from "../../forms/RegisterForm/RegisterEmailPhoneValidationForm";
import {
	StepsContextProvider,
	StepsContextType,
} from "../../contexts/StepsContext/StepsContext";
import RegisterPhone from "./RegisterPhone";
import TokenService from "../../services/TokenService";

export interface IRegisterStepProps {
	formRef: RefObject<any> | undefined;
	setStepsContext?: (context: StepsContextType) => void;
	stepsContext: StepsContextType;
	validateStep: (values: {}) => void;
	unvalidateStep: () => void;
	setError: (error: any) => void;
	setIsLoading: (isLoading: boolean) => void;
	isLoading: boolean;
	isActive: boolean;
}

const Register = () => {
	const params = useParams();
	const [init, setInit] = React.useState("");
	const [tries, setTries] = useState(3);

	const handleSubmitRegisterForm = (values: {
		token: string;
		propertyToCheck: string;
		value: string;
	}) => {
		return RegisterService.postIdentityValidate(values).catch((err) => {
			if (err) {
				setTries(tries - 1);
			}
			let errorMsg = err.response?.data?.message ?? "";
			if (errorMsg?.length)
				throw new Error(t("API_ERRORS." + errorMsg).toString());
			else throw err;
		});
	};

	const { isLoading, error } = useQuery(
		["IdentityCheck"],
		() => {
			const localToken = TokenService.getUserTokens();
			if (!!localToken?.token && localToken?.isOnboarding) {
				return Promise.resolve({ propertyToCheck: "dateOfBirth" });
			}
			TokenService.removeUser();
			return RegisterService.getIdentityCheck(
				params?.token ?? "",
				params?.origin ?? ""
			);
		},
		{
			refetchOnWindowFocus: false,
			enabled: !init ? true : false,
			onSettled: (data, error: any) => {
				if (data) {
					setInit(data.propertyToCheck);
				} else if (error) {
					if (error) {
						setTries(tries - 1);
					}
				}
			},
			onError: (error) => {
				TokenService.removeUser();
				toast.error(t("initRegister.error.identityCheck"), {
					autoClose: false,
				});
				setTries(0);
			},
		}
	);

	let steps: IStep[] = [
		{
			title:
				init === "dateOfBirth"
					? t("initRegister.birthDate")
					: t("initRegister.nameTitle"),
			name: init === "dateOfBirth" ? "dateOfBirth" : "lastName",
			component: RegisterForm,
			props: {
				init,
				tries,
				handleSubmitForm: handleSubmitRegisterForm,
			},
			validationSchema: RegisterFormValidation(t, init),
			submitOnNext: true,
			backgroundImg: Locker,
		},
		{
			title: t("initRegister.password"),
			name: "password",
			backgroundImg: Locker,
			component: RegisterPassword,
			validationSchema: ResetPasswordFormValidation(t),
			submitOnNext: true,
		},
		{
			title: t("initRegister.availibility"),
			name: "availibility",
			component: RegisterAvailabilities,
			submitOnNext: true,
			backgroundImg: Calendar,
		},
		{
			title: t("initRegister.email"),
			name: "email",
			component: RegisterEmail,
			validationSchema: RegisterEmailFormValidation(t),
			backgroundImg: Mail,
			submitOnNext: true,
		},
		{
			title: t("initRegister.phone"),
			name: "phone",
			component: RegisterPhone,
			validationSchema: RegisterPhoneFormValidation(t),
			submitOnNext: true,
			resetLoadingAfterSubmit: false,
			backgroundImg: Phone,
		},
	];

	const getInitialStep = () => {
		const token = TokenService.getUserTokens();
		return token?.token && token?.isOnboarding ? 3 : undefined;
	};

	return (
		<StepsContextProvider
			initValues={{
				validationCode: "",
				uniqueId: "",
				email: "",
				phone: "",
				availabilities: {
					morning: [],
					afternoon: [],
					night: [],
				},
			}}
		>
			<InitLayout
				steps={steps}
				isLoading={isLoading}
				error={error}
				stepsProps={{
					init,
				}}
				initialStep={getInitialStep()}
			/>
		</StepsContextProvider>
	);
};

export default Register;
