import { TAdvancePaymentAgency, TClosurePeriod } from "../types";
import api from "./api";

class AdvancePaymentServiceClass {
	getAdvancePayments() {
		return api.get("/api-360/advance-payments").then((response) => {
			return response.data;
		});
	}

	getClosureDates = (): Promise<TClosurePeriod[]> => {
		return api
			.get("/api-360/payroll-closure-periods/next")
			.then((response) => {
				return response.data;
			});
	};

	allowAdvancePayment = (): Promise<{
		agencies: TAdvancePaymentAgency[];
	}> => {
		return api
			.get("/api-360/worker/agencies-allowed-for-advance")
			.then((response) => {
				return response.data;
			});
	};
	postAdvancePayment = (data) => {
		return api.post("/api-360/advance-payments", data);
	};
}

const AdvancePaymentService = new AdvancePaymentServiceClass();
export default AdvancePaymentService;
