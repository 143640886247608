import React from "react";

import AdvancePaymentService from "../../services/AdvancePaymentService";

import { Button } from "@zolteam/react-ras-library";
import { Toggle } from "../../components/atoms/Toggle/Toggle";
import { Form, Formik } from "formik";
import Field from "../../components/molecules/Field/Field";
import AgencySelect from "../../components/molecules/AgencySelect/AgencySelect";
import * as Yup from "yup";
import { t } from "i18next";
import DatePickerField from "../../components/molecules/DatePickerField/DatePickerField";
import moment from "moment";
import { addDays, addWeeks, endOfMonth, endOfWeek } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../components/atoms/Spinner/Spinner";
import {
	TAdvancePaymentAgency,
	TAdvancePaymentFormValues,
	TClosurePeriod,
} from "../../types";

const AdvancePaymentFormValidation = () =>
	Yup.object().shape({
		agency: Yup.object().required(t("formValidation.requiredField")),
		isMaximumAdvanceRequested: Yup.boolean().required(
			t("formValidation.requiredField")
		),
		requestedAmount: Yup.number().when("isMaximumAdvanceRequested", {
			is: (val) => val === false,
			then: () =>
				Yup.number().required(t("formValidation.requiredField")).min(1),
			otherwise: () => Yup.number().nullable(),
		}),
		desiredPaymentDate: Yup.string().required(
			t("formValidation.requiredField")
		),
	});

interface IAdvancesPaymentProps {
	innerRef: React.MutableRefObject<any>;
	onCancel: () => void;
	onSubmit: (values) => void;
}

const getClosestFriday = (date: Date) => {
	const day = date.getDay();
	const diff = 5 - day;

	const newDate = new Date(date);
	newDate.setDate(date.getDate() + (diff >= 0 ? diff : diff + 7)); // if it's friday, it will return the next friday
	return newDate;
};

const calcMinDate = () => {
	// if current date is wednesday after 7am, the min date is the next friday
	const currentDate = new Date();
	const day = currentDate.getDay();
	const hour = currentDate.getHours();

	if ((day >= 3 && hour >= 7) || day > 3) {
		// 3 is wednesday, 18 is 6pm
		return getClosestFriday(endOfWeek(currentDate));
	}

	return getClosestFriday(currentDate);
};

const isDay = (date, dayNumber) => date.getDay() === dayNumber;

const isDayBetweenClosurePeriods = (
	date,
	closurePeriods?: TClosurePeriod[]
) => {
	const day = date.getDay();

	if (!closurePeriods?.length) return false;

	const closurePeriod = closurePeriods.find(
		(period) =>
			isDay(date, day) &&
			new Date(period.closureStartDate) <= date &&
			new Date(period.closureEndDate) >= date
	);

	return !!closurePeriod;
};

export const AdvancePaymentForm: React.FC<IAdvancesPaymentProps> = ({
	innerRef,
	onCancel,
	onSubmit,
}) => {
	const [Agencies, setAgencies] = React.useState<TAdvancePaymentAgency[]>([]);

	const fetchAgencies = () =>
		AdvancePaymentService.allowAdvancePayment().then((resp) => {
			const filtered = resp?.agencies?.filter(
				(agency) => !agency.hasAlreadyRequestedAdvancePaymentThisWeek
			);
			setAgencies(filtered);
			return { agencies: filtered };
		});

	const closurePeriodsQuery = useQuery({
		queryKey: ["AdvancePayment", "closurePeriods"],
		queryFn: () => AdvancePaymentService.getClosureDates(),
	});

	if (closurePeriodsQuery.isLoading) return <Spinner />;

	return (
		<Formik
			initialValues={
				{
					requestedAmount: "",
					isMaximumAdvanceRequested: false,
					agency: null,
					desiredPaymentDate: "",
				} as TAdvancePaymentFormValues
			}
			validationSchema={AdvancePaymentFormValidation()}
			onSubmit={onSubmit}
			innerRef={innerRef}
			validateOnMount
		>
			{({ values, setFieldValue, isValid, isSubmitting, errors }) => {
				return (
					<Form className="flex flex-col gap-6">
						<AgencySelect
							isMulti={false}
							query={{
								key: ["allowAdvancePayment"],
								fn: () =>
									fetchAgencies().then((resp) => {
										if (resp?.agencies?.length === 1) {
											setFieldValue("agency", {
												...resp.agencies[0],
												value: resp.agencies[0].id,
												label: resp.agencies[0].name,
											});
											if (
												resp.agencies[0]
													.withGenericAgency
											) {
												setFieldValue(
													"desiredPaymentDate",
													moment(
														calcMinDate()
													).format(t("dates.format"))
												);
											}
										}
										return resp;
									}),
							}}
							onChange={(agency) => {
								setFieldValue("agency", agency);
								setFieldValue(
									"desiredPaymentDate",
									moment(
										agency?.withGenericAgency
											? calcMinDate()
											: addDays(calcMinDate(), 7)
									).format(t("dates.format"))
								);
							}}
							value={values.agency}
							label={t(
								"advancePayment.advancePaymentRequest.agency"
							)}
							disabled={Agencies?.length <= 1}
						/>
						<p>
							{t(
								"advancePayment.advancePaymentRequest.placeholderLabel"
							)}
						</p>
						<Toggle
							label={t(
								"advancePayment.advancePaymentRequest.switchLabel"
							)}
							name="isMaximumAdvanceRequested"
							onChange={(event) => {
								setFieldValue(
									"isMaximumAdvanceRequested",
									event.target.checked
								);
							}}
							checked={values.isMaximumAdvanceRequested}
						/>
						{!values.isMaximumAdvanceRequested && (
							<Field
								label={t("advancePayment.amount")}
								name="requestedAmount"
								placeholder={t(
									"advancePayment.amountPlaceholder"
								)}
								type="text"
								onChange={(event) => {
									if (isNaN(Number(event.target.value)))
										return;
									setFieldValue(
										"requestedAmount",
										event.target.value
									);
								}}
								maxLength={4}
							>
								<span className="absolute text-lg -translate-y-1/2 right-4 top-7 text-neutral-500">
									€
								</span>
							</Field>
						)}
						<DatePickerField
							name="desiredPaymentDate"
							value={values.desiredPaymentDate}
							label={t(
								"advancePayment.advancePaymentRequest.desiredPaymentDate"
							)}
							onChange={(date) => {
								setFieldValue(
									"desiredPaymentDate",
									moment(date).format(t("dates.format"))
								);
							}}
							pickerProps={{
								filterDate: (day) =>
									isDay(day, 5) &&
									(values?.agency?.withGenericAgency ||
										!isDayBetweenClosurePeriods(
											day,
											closurePeriodsQuery.data
										)),
								excludeDates: [
									isDay(new Date(), 5) && new Date(),
								],
								minDate: calcMinDate(),
								maxDate: addWeeks(
									endOfMonth(getClosestFriday(new Date())),
									2
								),
							}}
							disabled={!values?.agency}
						/>
						<div className="flex flex-row items-end justify-end gap-4 pt-6 mt-6 border-t border-neutral-100">
							<Button
								color="primary"
								type="submit"
								outline
								onClick={onCancel}
								disabled={isSubmitting}
							>
								{t("global.cancel")}
							</Button>
							<Button
								color="primary"
								type="submit"
								disabled={!isValid || isSubmitting}
								isLoading={isSubmitting}
							>
								{t("global.send")}
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
