import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";
import {
	ModalV2,
	Title,
	Button,
	BackLink,
	InfoMessage,
} from "@zolteam/react-ras-library";
import Field from "../../components/molecules/Field/Field";
import { Form, Formik } from "formik";
import Spinner from "../../components/atoms/Spinner/Spinner";
import cn from "../../utils/cn";
import RegisterService from "../../services/RegisterService";
import { promiseToast } from "../../toastify/toastify";
import CountDown from "../../components/atoms/CountDown/CountDown";
import { Trans } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { RegisterEmailForm } from "../../forms/RegisterForm/RegisterEmailForm";
import { toast } from "react-toastify";

const SignUpEmailVerification: React.FC = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { state, pathname } = useLocation();

	const [modifyModal, setModifyModal] = useState(false);
	const [resendTimer, setResendTimer] = useState(false);
	const [sendingState, setSendingState] = useState({
		loading: false,
		error: "",
	});
	const token =
		pathname.indexOf("confirmation-email") !== -1 && params?.token
			? params?.token
			: null;

	const handleBackNavigation = () => navigate("/signup", { state: state });

	const reSendCode = () => {
		const prom = RegisterService.resendActivationCode(state.uniqueId).catch(
			(err) => {
				setSendingState({
					loading: false,
					error: err.response.data.message,
				});
				throw err;
			}
		);
		promiseToast(prom);
		setResendTimer(true);
		return prom;
	};

	const checkVerificationCode = (code, additionalState = {}) => {
		const localState = {
			...state,
			...additionalState,
		};

		setSendingState({ loading: true, error: "" });
		const prom = RegisterService.checkActivationCode(
			localState.uniqueId,
			code
		).then(
			(res) => {
				localState.activationCode = code;
				setTimeout(() => {
					navigate("/signup-password", {
						state: localState,
					});
				}, 1000);
				setSendingState({ loading: false, error: "" });
				return localState;
			},
			(err) => {
				setSendingState({
					loading: false,
					error: err.response.data.message,
				});
				throw err;
			}
		);
		promiseToast(prom);
		return prom;
	};

	const handleEmailChange = (values) => {
		const prom = RegisterService.postUserRegistration({
			...state,
			...values,
			consent: true,
		}).then(
			(resp) => {
				navigate("/signup-email-verification", {
					state: {
						...state,
						...resp,
						consent: true,
					},
				});
				setModifyModal(false);
			},
			({ response }) => {
				const msg = response?.data?.message;
				if (
					response.status === 409 &&
					msg === "ERR_CO_UTW_0001_UTW_ALREADY_EXISTS"
				) {
					return navigate("/retrieve-existing-account", {
						state: state,
					});
				} else toast.error(t("global.error"));
				return response;
			}
		);

		promiseToast(prom);

		return prom;
	};

	const checkToken = () => {
		if (!token) {
			return;
		}

		return RegisterService.checkMailActivationToken(token).then(
			(resp: { uniqueId: string; activationCode: string }) => {
				return checkVerificationCode(resp.activationCode, {
					...resp,
				});
			}
		);
	};

	const { isLoading: isLoadingFromToken, error: errorFromToken } = useQuery(
		["checkMailToken", params?.token],
		() => checkToken(),
		{
			retry: 0,
			refetchOnWindowFocus: false,
			enabled: !!token,
		}
	);

	if (!token && !state) {
		navigate("/signup");
		return;
	}

	const isLoading = sendingState.loading || (token && isLoadingFromToken);

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h2" size="heading01" className="!leading-9 mt-8 mb-4">
				{token
					? t("signup.emailVerification.checkingMailTokenTitle")
					: t("signup.emailVerification.title")}
			</Title>
			{token && isLoadingFromToken && <Spinner className="w-fit" />}
			{!token && (
				<>
					<InfoMessage color="warning" withIcon>
						<Trans
							i18nKey="signup.emailVerification.text"
							values={{
								email: state.email,
							}}
							className="text-sm leading-6"
							parent={"p"}
						/>
					</InfoMessage>

					<Formik
						initialValues={{
							activationCode: "",
						}}
						validateOnChange
						validateOnBlur={false}
						onSubmit={() => {
							// prop is mandatory but the submission is triggered by the validation
							// when the code reaches 4 characters
						}}
						validate={({ activationCode }) => {
							if (activationCode?.length === 4) {
								checkVerificationCode(activationCode);
							}
						}}
					>
						{() => {
							return (
								<Form>
									<div className="flex items-center gap-4 mt-8 mb-4">
										<Field
											name="activationCode"
											className={cn([
												"w-[200px] [&>div]:!rounded-2xl",
												"[&>*>input]:!pl-[1.2rem] [&>*>input]:!tracking-[0.4rem] [&>*>input]:!p-[10px] [&>*>input]:!text-center [&>*>input]:!text-2xl",
												isLoading &&
													"[&>*>input]:!text-neutral-500",
											])}
											type="text"
											label=""
											maxLength={4}
											readOnly={!!isLoading}
										/>
										{isLoading && <Spinner />}
									</div>
								</Form>
							);
						}}
					</Formik>
					<div>
						<Title tag="h3" size="heading03" className="mb-0">
							{t("signup.emailVerification.notReceived")}
						</Title>
						<div className="flex flex-wrap gap-4">
							<Button
								color="primary"
								outline
								className={cn([resendTimer && "opacity-70"])}
								disabled={resendTimer}
								onClick={resendTimer ? undefined : reSendCode}
								type="button"
							>
								{t("signup.emailVerification.resend")}
								{resendTimer && (
									<CountDown
										time={30}
										text={(time) => ` (${time})`}
										handleEnd={() => setResendTimer(false)}
									/>
								)}
							</Button>
							<Button
								color="primary"
								outline
								onClick={() => setModifyModal(true)}
								type="button"
							>
								{t("signup.emailVerification.change")}
							</Button>
						</div>
					</div>
					<ModalV2
						isDisplayed={modifyModal}
						onClose={() => setModifyModal(false)}
						title={t("signup.emailVerification.change")}
						size="s"
						closeOnClickOutside={false}
					>
						<RegisterEmailForm
							handleSubmitForm={(values) => {
								return handleEmailChange(values);
							}}
							submitButton
							submitButtonText={t(
								"signup.emailVerification.resendToMail"
							)}
							submitButtonDisabled={(values) => {
								return values.email === state.email;
							}}
						/>
					</ModalV2>
				</>
			)}

			{!isLoadingFromToken && !!errorFromToken && (
				<InfoMessage color="error" className="mt-3">
					{errorFromToken &&
						t(
							`API_ERRORS.${errorFromToken?.response?.data?.message}`
						)}
				</InfoMessage>
			)}
			{!isLoading && !!sendingState.error?.length && (
				<InfoMessage color="error" className="mt-3">
					{sendingState.error &&
						t(`API_ERRORS.${sendingState.error}`)}
				</InfoMessage>
			)}
		</AuthLayout>
	);
};

export default SignUpEmailVerification;
