import * as Yup from "yup";
import { EMAIL_REGEX, PHONE_REGEX } from "../../utils/regex";

export const RegisterEmailFormInitValues = {
	email: "",
	emailConfirmation: "",
};

export const RegisterPhoneNumberFormInitValues = {
	phoneNumber: "",
};

export const RegisterEmailFormValidation = (t) => {
	const initialMail = JSON.parse(
		sessionStorage.getItem("RegisterForm") || "{}"
	).email;
	return Yup.object().shape({
		email: Yup.string()
			.required(t("registerEmail.error.required"))
			.test("email", t("registerEmail.error.shouldBeMail"), (value) =>
				value ? EMAIL_REGEX.test(value) : true
			),
		// emailConfirmation if email is different from initial email
		emailConfirmation: Yup.string().when("email", {
			is: (email) => {
				return initialMail && email !== initialMail;
			},
			then: () =>
				Yup.string()
					.required(t("registerEmail.error.required"))
					.oneOf(
						[Yup.ref("email")],
						t("initRegister.error.emailConfirm")
					),
			otherwise: () => Yup.string().nullable(),
		}),
	});
};

export const RegisterPhoneFormValidation = (t) =>
	Yup.object().shape({
		phoneNumber: Yup.string()
			.test(
				"phoneNumber",
				t("registerPhone.error.shouldBePhone"),
				(value) => (value ? PHONE_REGEX.test(value) : true)
			)
			.nullable(),
	});
