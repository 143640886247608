import React, { Ref, useState } from "react";
import { Title, Button, Picto, InfoMessage } from "@zolteam/react-ras-library";
import Waves from "../../molecules/Waves/Waves";
import StepWizard from "react-step-wizard";
import "./InitLayout.scss";
import { DefaultTFuncReturn, t } from "i18next";
import cn from "../../../utils/cn";
import { ProgressBar } from "../../molecules/ProgressBar/ProgressBar";
import Loader from "../../atoms/Loader/Loader";
import { Trans } from "react-i18next";
import { FormikProps } from "formik";
import SVG from "../../atoms/SVG/SVG";
import { pushDataLayer } from "../../../GoogleTagManager/gtm";
import { useNavigate } from "react-router-dom";
import QuestionMark from "../../../assets/QuestionMark.svg";

interface IInitLayout {
	title?: string | DefaultTFuncReturn | React.ReactNode; // the title of the page
	subtitle?: string | DefaultTFuncReturn | React.ReactNode; // the subtitle of the page
	text?: string | DefaultTFuncReturn | React.ReactNode; // the text of the page
	steps: IStep[]; // the steps to display
	stepsProps?: any; // the props to pass to the steps
	isLoading?: boolean; // used to display a loader
	error?: any; // used to display an error message
	finalScreen?: any; // used to display a screen when the steps are done
	allowBack?: boolean; // used to allow the user to go back
	initialStep?: number; // the initial step
}

export interface ICurStep {
	previousStep: number; // the index of the previous step
	activeStep: number; // the index of the current step
	isStepValid: boolean; // used to know if the current step has been validated with the formik form
	validateStep?: (values: {}) => Promise<any>; // used to validate the formik form in the current step
	unvalidateStep?: () => void; // used to unvalidate the formik form in the current step
	submitStep?: () => Promise<any>; // used to submit the formik form in the current step
	customNextCallback?: undefined | null | (() => void); // used to call a function when the user click on the next button,
	setCustomNextCallback?: (func: () => void) => void; // used to set the customNextStep function
	formRef?: Ref<FormikProps<any>>; // the ref of the formik form in the step
}

export interface IStepComponentProps {
	validateStep: (values: {}) => Promise<any>; // used to validate the formik form in the current step
	unvalidateStep: () => void; // used to unvalidate the formik form in the current step
	isStepValid: boolean; // used to know if the current step has been validated with the formik form
	submitStep: (forcedFormRef?: any) => Promise<any>; // used to submit the formik form in the current step
	setIsLoading: (isLoading: boolean) => void; // used to set the isLoading state
	isLoading?: boolean; // used to know if the step is loading
	isActive: boolean; // used to know if the step is active
	formRef?: Ref<FormikProps<any>>; // the ref of the formik form in the step
	curStep: ICurStep; // the current step
	setError?: (error: any) => void; // used to set the error message
	setCustomNextCallback?: (func: () => void) => void; // used to set the customNextStep function
	customNextCallback?: undefined | null | (() => void); // used to call a function when the user click on the next button,
}

export interface IStep {
	title: string; // the title of the step
	name: string; // the name of the step, used as a unique identifier
	component?: any; // the component to display in the step
	backgroundImg?: string; // the background image of the step
	props?: any; // the props to pass to the component
	validationSchema?: any; // the validation schema of the formik form in the step
	submitOnNext?: boolean; // used to submit the formik form when the user click on the next button
	resetLoadingAfterSubmit?: boolean; // if false, the loading state will not be reset after the form submission
	formRef?: Ref<FormikProps<any>>; // the ref of the formik form in the step
	isActive?: boolean; // used to know if the step is active
	setError?: (error: any) => void; // used to set the error message
	enableBackNavigation?: boolean; // used to enable the back button
}

interface IStepNav {
	instance?: any;
	curStep?: ICurStep;
	isLoading: boolean;
	isDone: boolean;
	canGoToPreviousStep: boolean;
	goToNextStep: () => void;
}

const initSteps = (steps: IStep[]) => {
	if (!steps?.length) return [];
	return steps.map((step, index) => {
		const newStep: IStep = {
			...step,
			formRef: React.createRef(),
		};
		return newStep;
	});
};

const InitLayout: React.FC<IInitLayout> = (props: IInitLayout) => {
	const navigate = useNavigate();

	const [instance, setInstance] = useState<any>();
	const [CurStep, setCurStep] = useState<ICurStep>({
		previousStep: props.initialStep ? props.initialStep - 1 : 1,
		activeStep: props.initialStep ? props.initialStep - 1 : 1,
		isStepValid: false,
		customNextCallback: null,
		setCustomNextCallback: (func: () => void) => {
			setCurStep({
				...CurStep,
				customNextCallback: func,
			});
		},
	});
	const [CanGoToPreviousStep, setCanGoToPreviousStep] = useState<boolean>(
		props.allowBack ?? false
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [Error, setError] = useState<any>({});
	const [Steps, setSteps] = useState<IStep[]>(initSteps(props.steps));
	const [isDone, setIsDone] = useState<boolean>(false);

	const datalayerPageTitle = "Connexion";

	// update step if props.isLoading change
	React.useEffect(() => {
		setSteps(initSteps(props.steps));
		if (props.initialStep) instance?.goToStep(props.initialStep, true);
	}, [props.isLoading, props.steps, props.initialStep, instance]);

	// used to update the view
	function onStepChange(step: { previousStep: number; activeStep: number }) {
		setCurStep({
			...step,
			isStepValid: step.previousStep > step.activeStep,
			customNextCallback: null,
		});
		setCanGoToPreviousStep(
			Steps[step.activeStep - 1].enableBackNavigation ??
				CanGoToPreviousStep
		);
		setError({});
		return false;
	}

	// used to validate the formik form in the current step
	function validateStep(step: IStep, values: {}) {
		let prom = Promise.resolve();

		if (step.validationSchema) {
			prom = step.validationSchema.validate(values);
		}
		prom.then(
			() => {
				setCurStep({
					...CurStep,
					isStepValid: true,
				});
			},
			(err) => {
				setCurStep({
					...CurStep,
					isStepValid: false,
				});
				return err;
			}
		);
		return prom;
	}

	// used to go to the next step from the StepNav "next" button
	const nextStep = (forcedFormRef) => {
		const curStep: IStep = Steps[CurStep.activeStep - 1];
		const formRef: any = forcedFormRef ?? curStep.formRef;

		let prom = Promise.resolve();

		// the formik submit function will be called before going to the next step if it exists
		if (curStep.submitOnNext && formRef?.current) {
			setError({});
			setIsLoading(true);
			prom = formRef.current.submitForm();
		}

		prom.then(
			(resp) => {
				if (CurStep.activeStep < Steps.length) instance.nextStep();
				else setIsDone(true);
				if (curStep.resetLoadingAfterSubmit === false) return;
				setIsLoading(false);
			},
			(err) => {
				setError(err);
				setIsLoading(false);
			}
		);

		return prom;
	};

	const navigateHelp = () => {
		pushDataLayer({
			dataLayer: {
				event: "demande_aide",
				pageUrl: window.location.href,
				pageTitle: datalayerPageTitle,
			},
		});
		navigate("/help");
	};

	return (
		<div
			className={cn([
				"InitLayout flex h-full w-full bg-neutral-50 overflow-hidden",
				props.isLoading && "isLoading",
			])}
		>
			<Waves className="w-3/12 md:w-6/12 md:flex h-full left-[-5vw] md:left-0 z-10 sm:z-0 [&>div]:flex [&>div]:flex-col [&>div]:justify-between pointer-events-none">
				<div className="hidden w-1/12 md:w-auto md:block">
					<Title tag="h1" size="display02" className="!text-white">
						{props.title ?? t("initRegister.title")}
					</Title>
					<p className="mt-4 mb-8">
						{props.subtitle ?? (
							<Trans i18nKey="initRegister.text" />
						)}
					</p>
					<p className="mb-8">
						{props.text ?? t("initRegister.text2")}
					</p>
					{instance && (
						<div
							key={instance?.state.activeStep}
							className="pointer-events-auto"
						>
							<StepsLinks
								key={instance?.state.activeStep}
								steps={Steps}
								instance={instance}
								curStep={CurStep}
								goToNextStep={nextStep}
								isLoading={isLoading}
								isDone={isDone}
								enableBackNavigation={CanGoToPreviousStep}
							/>
						</div>
					)}
				</div>
				<button
					className="absolute -right-[12px] top-8 rotate-90 sm:relative sm:rotate-0 sm:right-0 sm:top-0 flex items-center !text-white gap-2 mt-auto text-sm pointer-events-auto"
					onClick={navigateHelp}
				>
					<SVG
						src={QuestionMark}
						className="[&>*]:!stroke-white"
						style={{
							width: "1.25rem",
						}}
					/>
					<p className="md:hidden">Aide</p>
					<p className="hidden md:block">{t("global.needHelp")}</p>
				</button>
			</Waves>
			<div className="main-cont relative flex flex-col w-full h-full sm:w-10/12 md:w-6/12 lg:w-7/12 xl:w-8/12 p-8 pb-[100px] sm:pb-8 sm:p-4 sm:pr-[8%] md:pr-[5%] pt-[0vh] duration-500 z-0 overflow-auto bg-center bg-no-repeat bg-[length:90%]">
				<span
					className="absolute left-0 top-0 h-full w-full bg-no-repeat bg-center opacity-10 !pointer-events-none !cursor-default"
					style={{
						backgroundImage: `url(${
							!props.isLoading && !isLoading
								? Steps[CurStep.activeStep - 1]?.backgroundImg
								: ""
						})`,
						backgroundSize: "90%",
					}}
				/>
				<ProgressBar
					className="md:hidden min-h-[0.5rem]"
					completed={
						((instance?.state.activeStep + 1) * 100) / Steps.length
					}
				/>
				<StepWizard
					onStepChange={onStepChange}
					instance={setInstance}
					nav={
						<StepsNav
							instance={instance}
							curStep={CurStep}
							goToNextStep={() => nextStep(CurStep.formRef)}
							isLoading={isLoading}
							isDone={isDone}
							canGoToPreviousStep={CanGoToPreviousStep}
						/>
					}
					className="flex flex-col flex-1 StepWizard"
				>
					{Steps?.map((step: any, index: number) => (
						<div
							className={cn([
								"step flex flex-col bg-left bg-no-repeat bg-contain md:justify-center m-auto sm:m-unset	w-fit sm:w-auto",
								CurStep.activeStep === index + 1
									? "active"
									: "!pointer-events-none !cursor-default",
							])}
							key={index}
						>
							{props.isLoading || props.error ? (
								props.isLoading && (
									<Loader className="mx-auto w-xl" text="" />
								)
							) : isDone && props.finalScreen ? (
								<>
									<props.finalScreen
										isActive={true}
										setIsLoading={setIsLoading}
										setError={setError}
										{...props.stepsProps}
									/>
								</>
							) : (
								<>
									{step.component && (
										<step.component
											validateStep={(values: {}) =>
												validateStep(step, values)
											}
											unvalidateStep={() => {
												setCurStep({
													...CurStep,
													isStepValid: false,
												});
											}}
											isStepValid={CurStep.isStepValid}
											isLoading={isLoading}
											setIsLoading={setIsLoading}
											isActive={
												CurStep.activeStep === index + 1
											}
											submitStep={(formRef) =>
												nextStep(formRef)
											}
											{...props.stepsProps}
											{...step.props}
											formRef={step.formRef}
											curStep={CurStep}
											setCustomNextCallback={(
												func: () => void
											) => {
												setCurStep({
													...CurStep,
													customNextCallback: func,
												});
											}}
											customNextCallback={
												CurStep.customNextCallback
											}
											setError={setError}
										/>
									)}
								</>
							)}
							{Error?.message?.length > 0 && (
								<InfoMessage withIcon color="error">
									{Error?.message}
								</InfoMessage>
							)}
						</div>
					))}
				</StepWizard>
			</div>
		</div>
	);
};

const StepsLinks: React.FC<any> = (props: any) => {
	const { instance, isDone } = props;

	const isClickable = (index: number) => {
		/*
			si
				on clique sur l'étape en cour
			ou si
				l'étape en cour est valide et que on clique sur l'étape suivante
			ou si
				l'étape en cour n'est pas valide mais que l'ont clique sur une étape précedente
		*/
		const curStep = props.steps[instance?.state.activeStep - 1];

		if (
			(index < instance.state.activeStep && props.enableBackNavigation) ||
			(props.curStep?.isStepValid &&
				!curStep?.submitOnNext &&
				index === instance.state.activeStep + 1)
		)
			return true;
		return false;
	};

	return (
		<ul className="flex flex-col StepsLinks gap-m">
			{props.steps.map((step: any, index: number) => (
				<li key={index} className="list-none">
					<button
						className={cn([
							"step-link flex gap-m text-white items-center opacity-60 text-left leading-5",
							index === instance.state.activeStep && !isDone
								? "before:outline before:outline-2 before:outline-rating-range-2 !opacity-100"
								: index < instance.state.activeStep || isDone
								? " before:bg-rating-range-2"
								: "",
							!props.enableBackNavigation &&
								"disabled:!cursor-default",
						])}
						disabled={!isClickable(index)}
						onClick={() => {
							const { curStep } = props;
							if (!isClickable(index)) return false;
							if (curStep?.customNextCallback)
								curStep?.customNextCallback();
							else instance.goToStep(index + 1);
						}}
					>
						{t(step.title)}
					</button>
				</li>
			))}
		</ul>
	);
};

const StepsNav: React.FC<IStepNav> = (props: IStepNav) => {
	const { instance, goToNextStep, isDone } = props;

	if (!instance || isDone) return null;
	const index = instance.state.activeStep;

	return (
		<div className="flex flex-col-reverse flex-wrap items-center justify-between order-1 StepsNav sm:flex-row sm:flex-nowrap md:flex-wrap lg:flex-nowrap gap-m">
			{index > 0 && (
				<div className="w-full sm:w-auto md:w-full lg:w-auto">
					<Button
						className={cn([
							"w-full !bg-white",
							!props.canGoToPreviousStep && "invisible",
						])}
						color="primary"
						outline
						onClick={() => instance.previousStep()}
						type="submit"
						isLoading={props.isLoading}
					>
						<Picto icon={"chevronLeft"} />
						{t("stepWizard.previous")}
					</Button>
				</div>
			)}
			<div className="w-full ml-auto sm:w-auto md:w-full lg:w-auto">
				<Button
					className="flex justify-between w-full gap-2"
					color={props.curStep?.isStepValid ? "primary" : "grey"}
					disabled={!props.curStep?.isStepValid}
					onClick={props.curStep?.customNextCallback ?? goToNextStep}
					type="submit"
					isLoading={props.isLoading}
				>
					{t("stepWizard.continue")}
					<Picto
						icon={"chevronRight"}
						className="w-auto [&>*]:!text-white !mr-0"
					/>
				</Button>
			</div>
		</div>
	);
};

export default InitLayout;
