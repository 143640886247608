import { Formik, Form } from "formik";
import React from "react";
import {
	SignUpFormInitValues,
	SignUpFormValidation,
} from "./SignUpFormValidation";
import { t } from "i18next";
import { Trans } from "react-i18next";
import Field from "../../components/molecules/Field/Field";
import { InfoMessage, Button, Text } from "@zolteam/react-ras-library";
import { useLocation, useNavigate } from "react-router-dom";
import { pushDataLayer } from "../../GoogleTagManager/gtm";
import RegisterService from "../../services/RegisterService";
import { toast } from "react-toastify";
import { getAPIError } from "../../services/api";

const SignUpForm: React.FC = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const [hasMailError, setHasMailError] = React.useState(false);

	const handleSubmit = (values, helpers) => {
		setHasMailError(false);
		pushDataLayer({
			dataLayer: {
				event: "inscription__creer_compte",
				telephone: values.phoneNumber ? "yes" : "no",
			},
		});

		const prom = RegisterService.postUserRegistration({
			...values,
			consent: true,
		}).then(
			(resp) => {
				navigate("/signup-email-verification", {
					state: {
						...values,
						...resp,
						consent: true,
					},
				});
			},
			({ response }) => {
				const msg = getAPIError(response, "email", true);
				const key = `API_ERRORS.${msg}`;
				const trad = t(key);

				if (
					msg === "ERROR_FORBIDDEN_EMAIL_NOT_UNIQUE" ||
					msg === "ERROR_CONFLICT_UNIFIED_TEMP_WORKER_ALREADY_EXISTS"
				) {
					setHasMailError(true);
					helpers.setFieldError(
						"email",
						t(`API_ERRORS.ERROR_FORBIDDEN_EMAIL_NOT_UNIQUE`)
					);
				} else {
					toast.error(trad === key ? t("global.error") : trad);
				}

				return response;
			}
		);

		return prom;
	};

	return (
		<div>
			<Formik
				initialValues={{ ...SignUpFormInitValues, ...state }}
				validationSchema={SignUpFormValidation(t)}
				validateOnMount={true}
				onSubmit={handleSubmit}
			>
				{({ values, isValid, dirty, isSubmitting, errors }) => {
					const canSubmit = isValid && (state || dirty);
					return (
						<Form className="flex flex-col gap-5 -mt-4">
							<InfoMessage withIcon>
								<strong>{t("signup.consent.title")}</strong>
								<p className="mt-1">
									{t("signup.consent.text")}
								</p>
							</InfoMessage>
							{hasMailError && (
								<div className="flex flex-col gap-2 [&_div]:mt-0">
									<InfoMessage type="primary" withIcon>
										<Trans
											i18nKey="register.retrieveCTA"
											components={[
												<a
													href="/login-retrieve"
													className="font-bold"
												>
													{""}
												</a>,

												<a
													href="/help"
													className="font-bold"
												>
													{""}
												</a>,
											]}
										/>
									</InfoMessage>
								</div>
							)}
							<Field
								name="firstName"
								type="text"
								label={t("initRegister.firstName")}
							/>
							<Field
								name="lastName"
								type="text"
								label={t("initRegister.name")}
							/>
							<Field
								name="email"
								type="email"
								label={t("initRegister.email")}
							/>
							<Field
								name="emailConfirm"
								type="emailConfirm"
								label={t("initRegister.emailConfirm")}
								className="mb-4"
							/>
							<Text
								tag="p"
								size="paragraph01"
								className={"!text-neutral-500"}
							>
								{t("signup.consent.byAllowing")}{" "}
								<button
									className="inline-block underline underline-offset-2"
									onClick={() =>
										window.open(
											"https://myras.fr/politique-de-confidentialite/",
											"_blank"
										)
									}
								>
									{t("signup.consent.confidentialityPolicy")}
								</button>
								.
							</Text>

							<Button
								color={canSubmit ? "primary" : "grey"}
								disabled={!canSubmit}
								type="submit"
								className="!ml-0"
								isLoading={isSubmitting}
							>
								{t("register.title")}
							</Button>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default SignUpForm;
