import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackLink, Title, Text, Button } from "@zolteam/react-ras-library";
import { t } from "i18next";
import AuthLayout from "../../components/templates/AuthLayout/AuthLayout";
import RegisterService from "../../services/RegisterService";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";

const SignUpConsent: React.FC = () => {
	// Get id from state from previous page in react
	const [isLoading, setIsLoading] = useState(false);

	const { state } = useLocation();

	const navigate = useNavigate();

	const handleBackNavigation = () => {
		navigate("/signup", { state: state });
	};

	const handleConsent = () => {
		setIsLoading(true);

		const mainProm = new Promise((resolve, reject) => {
			const prom = RegisterService.postUserRegistration({
				...state,
				consent: true,
			}).then(
				(resp) => {
					navigate("/signup-email-verification", {
						state: {
							...state,
							...resp,
							consent: true,
						},
					});
				},
				({ response }) => {
					setIsLoading(false);
					const msg = response?.data?.message;
					if (
						response?.status === 409 &&
						msg === "ERR_CO_UTW_0001_UTW_ALREADY_EXISTS"
					) {
						return navigate("/retrieve-existing-account", {
							state: state,
						});
					} else toast.error(t("global.error"));
					return response;
				}
			);

			return prom;
		});

		return mainProm;
	};

	if (!state || !state?.firstName || !state?.lastName || !state?.email) {
		navigate("/signup");
		return null;
	}

	const { firstName, lastName, email } = state;

	return (
		<AuthLayout>
			<BackLink theme="primary" onClick={handleBackNavigation}>
				{t("global.back")}
			</BackLink>
			<Title tag="h2" size="heading01" className="!leading-9 my-8">
				<Trans i18nKey="signup.consent.title" />
			</Title>
			<Text tag="p" size="paragraph01">
				{t("signup.consent.text")}
			</Text>
			<Text
				tag="p"
				size="paragraph01"
				className={"!font-bold mt-6 capitalize"}
			>
				{firstName} {lastName}
			</Text>
			<Text tag="p" size="paragraph01" className={"!font-bold mb-6"}>
				{email}
			</Text>
			<Text tag="p" size="paragraph01" className={"!text-neutral-500"}>
				{t("signup.consent.byAllowing")}{" "}
				<button
					className="inline-block underline underline-offset-2"
					onClick={() =>
						window.open(
							"https://myras.fr/politique-de-confidentialite/",
							"_blank"
						)
					}
				>
					{t("signup.consent.confidentialityPolicy")}
				</button>
				.
			</Text>
			<Button
				color="primary"
				isLoading={isLoading}
				className="mt-6"
				type="button"
				onClick={handleConsent}
			>
				{t("signup.consent.allow")}
			</Button>
		</AuthLayout>
	);
};

export default SignUpConsent;
