import React, { useContext } from "react";
import { RegisterEmailForm } from "../../forms/RegisterForm/RegisterEmailForm";
import RegisterService from "../../services/RegisterService";
import RegisterInitService from "../../services/RegisterInitService";
import { useQuery } from "@tanstack/react-query";
import { Title } from "@zolteam/react-ras-library";
import { t } from "i18next";
import { RegisterCodeForm } from "../../forms/RegisterForm/RegisterCodeForm";
import {
	StepsContext,
	StepsContextType,
} from "../../contexts/StepsContext/StepsContext";
import Loader from "../../components/atoms/Loader/Loader";
import { promiseToast } from "../../toastify/toastify";
import { IRegisterStepProps } from "./Register";
import { getAPIError } from "../../services/api";

interface IRegisterEmailFormValues {
	lastName: string;
	firstName: string;
	phoneNumber: string;
	email: string;
	consent: boolean;
}

const RegisterEmail: React.FC<IRegisterStepProps> = (props) => {
	const { uniqueId, setStepsContext } =
		useContext<StepsContextType>(StepsContext);

	const [UserData, setUserData] = React.useState<IRegisterEmailFormValues>({
		lastName: "",
		firstName: "",
		phoneNumber: "",
		email: "",
		consent: false,
	});

	const [IsPopperOpen, setIsPopperOpen] = React.useState(false);
	const [CodeError, setCodeError] = React.useState("");
	const [PromiseCallbacks, setPromiseCallbacks] = React.useState({
		isCodeValid: false,
		resolve: (value) => {},
		reject: (err) => {},
	});

	const formContext = props.formRef?.current;

	const handleSubmitRegisterForm = (values: { email: string }) => {
		if (!formContext) {
			setCodeError("form context is undefined");
			return;
		}
		RegisterInitService.setValue("email", values.email);
		setCodeError("");
		let prom = new Promise((resolve, reject) => {
			setPromiseCallbacks({
				...PromiseCallbacks,
				resolve,
				reject,
			});

			formContext.setFieldValue("email", values.email);
			setUserData({ ...UserData, email: values.email });
			if (formContext.values.email !== formContext.initialValues.email)
				return sendCode(values.email).then(() => setIsPopperOpen(true));
			else resolve(true);
		});
		return prom;
	};

	const sendCode = (mail) => {
		props.setIsLoading(true);
		let sendProm = RegisterService.sendMediaSecurityToken({
			uniqueId,
			email: { value: mail },
		}).then(
			(resp) => {
				props.setIsLoading(false);
				return resp;
			},
			(err) => {
				const errorMessage = getAPIError(err, "email");
				setCodeError(errorMessage);
				props.setError({
					message: errorMessage,
				});
				props.setIsLoading(false);
				return Promise.reject(err);
			}
		);
		promiseToast(
			sendProm,
			{
				pending: t("initRegister.codeSend").toString(),
			},
			{
				toastId: "sendMailCode",
			}
		);
		return sendProm;
	};

	const handleSubmitCodeForm = (values: { activationCode: string }) => {
		return RegisterService.putVerifySecurityToken({
			uniqueId,
			securityToken: values.activationCode,
			email: { value: UserData.email },
		}).then(
			(res) => {
				formContext.initialValues.email = UserData.email;
				props.setError({});
				setIsPopperOpen(false);
				setPromiseCallbacks({ ...PromiseCallbacks, isCodeValid: true });
				return PromiseCallbacks.resolve(res);
			},
			(err) => {
				let errorMessage = t(
					"API_ERRORS." + (err.response?.data?.message ?? "default")
				);
				setCodeError(errorMessage);
				props.setError({ message: errorMessage });
				setPromiseCallbacks({
					...PromiseCallbacks,
					isCodeValid: false,
				});
				props.setIsLoading(false);
			}
		);
	};

	const fetchEmailInfo = () => {
		return RegisterService.getUserRegistration().then(
			(res) => {
				RegisterInitService.setValues(res);
				setUserData(res);
				setStepsContext({
					...res,
					uniqueId: uniqueId || res.uniqueId,
				});
				return res.email;
			},
			(err) => {
				console.error(err.message);
			}
		);
	};

	const { isLoading: stepLoad, data } = useQuery(
		["registerEmail"],
		fetchEmailInfo,
		{
			enabled: props.isActive,
			refetchOnWindowFocus: false,
		}
	);
	if (stepLoad) return <Loader />;

	return (
		<div>
			<Title
				tag="h2"
				lineHeight="m"
				size="heading01"
				className={"pb-xxl"}
			>
				{data ? t("registerEmail.title2") : t("registerEmail.title")}
			</Title>
			<RegisterEmailForm
				handleSubmitForm={handleSubmitRegisterForm}
				email={data}
				formRef={props.formRef}
				validateStep={props.validateStep}
			/>
			{IsPopperOpen && (
				<RegisterCodeForm
					error={CodeError}
					email={UserData.email}
					handleResendCode={() => sendCode(UserData.email)}
					handleSubmitForm={handleSubmitCodeForm}
					isLoading={props.isLoading}
					onClose={() => {
						setIsPopperOpen(false);
						if (!PromiseCallbacks.isCodeValid)
							props.setIsLoading(false);
					}}
				/>
			)}
		</div>
	);
};
export default RegisterEmail;
